<div class="alternative-header-container">
  <div class="information-container" [ngClass]="{ 'hide-container' : menu }">
    <div class="upper-container">
      <img src="/assets/icons/menu-icon.svg" alt="" class="menu-icon" (click)="openMenu()">
      <img src="/assets/logo.svg" alt="" class="logo">
    </div>
    <div class="information">
      <div class="first-container">
        <span class="mti-h3">Natural stone</span>
        <span class="mti-h3">Hardwood</span>
        <span class="mti-h3">Tile</span>
      </div>
      <div class="second-container">
        <span class="mti-body-mobile">bathroom renovation</span>
        <span class="mti-h2">Megamaster Tile Inc.</span>
      </div>
      <div class="third-container">
        <span class="mti-h3">Location: Etobicoke On</span>
        <span class="mti-h3">Canada</span>
      </div>
      <div class="fourth-container">
        <span class="mti-span">0{{activeSlideIndex || 1}}/0{{alternativeHeaderMock.length}}</span>
        <div class="swiper-buttons-container">
          <img src="/assets/icons/alternative-header-arrow.svg" alt="" class="arrow-left swiper-button-prev-image">
          <span class="mti-span2">{{activeSlideIndex || 1}}</span>
          <img src="/assets/icons/alternative-header-arrow.svg" alt="" class="arrow-right swiper-button-next-image">
        </div>
      </div>
    </div>
  </div>
  <div class="mobile-menu" *ngIf="menu">
    <div class="mobile-navigation">
      <img src="/assets/icons/menu-icon.svg" alt="" class="menu-icon" (click)="openMenu()">
      <a href="" routerLink="/home" (click)="closeMenu('home')"><span class="navigation-btn-text">Home</span></a>
      <a href="" routerLink="/about" (click)="closeMenu()"><span class="navigation-btn-text">About us</span></a>
      <a href="" routerLink="/portfolio" (click)="closeMenu()"><span class="navigation-btn-text">Portfolio</span></a>
      <a href="" routerLink="/contact" (click)="closeMenu()"><span class="navigation-btn-text">Contact</span></a>
    </div>
    <div class="information-container">
      <div class="phone-container container">
        <img src="/assets/icons/phone.svg" alt="" class="phone icon">
        <div class="phones">
          <span class="mti-h3">+1 (416) 720-0510 <br> (Polish,Ukrainian)</span>
        </div>
      </div>
      <div class="email-container container">
        <img src="/assets/icons/email.svg" alt="" class="email icon">
        <div class="emails">
          <span class="mti-h3">Megamaster2022ink@gmail.com</span>
        </div>
      </div>
      <div class="address-container container">
        <img src="/assets/icons/location.svg" alt="" class="address icon">
        <span class="mti-h3">2545 KINGSBERRY CRES <br> MISSISSAUGA ON L5B 2K7 <br> CANADA</span>
      </div>
    </div>
  </div>
  <div class="image-container">
    <div class="swiper">
      <div class="swiper-wrapper">
        <img *ngFor="let alternativeHeaderImage of alternativeHeaderMock"
             src="{{alternativeHeaderImage.src}}"
             alt=""
             class="swiper-slide">
      </div>
      <div class="swiper-scrollbar"></div>
    </div>
  </div>
</div>
